<template>
  <AppPage :name="'aboutus'">
    <div class="main">
      <div class="content">
        <div class="lh-24 fs-16 text-666 p-20">
          <div>神奇口袋App，简单好用的省钱神器！淘宝、京东、拼多多、唯品会等各大平台上超过90%的商品都能帮您省钱买到！外卖还能领红包拿返现。</div>
          <div class="mt-20">精选版块，为您及时提供省钱爆料 </div>
          <div class="mt-20"><strong>【每日大牌漏洞价，实时报给你】</strong></div>
          <div>为什么总是错过超值的优惠？因为你没用「神奇口袋」呀！</div>
          <div>用户实时爆料各大电商平台上今日最值的好物！</div>
          <div class="mt-20"><strong>【全网白菜价，9.9元购】</strong></div>
          <div>每日精选9.9元购，超值商品9.9元带走！</div>
          <div class="mt-20"><strong>【热门种草社区，超多精彩推荐】</strong></div>
          <div>逛淘宝时见到了漂亮的宝贝，有没有分享的欲望？来，「神奇口袋」满足你！</div>
          <div>海量网友将逛淘宝时发现的美物，做成清单分享给你！高质量好物推荐每天看不够！</div>
        </div>
      </div>

    </div>
    <div class="ml-20"></div>
    <div class="side">
      
      <AppQRCode />
      <Copyright />
    </div>
  </AppPage>
</template>
<script>
export default {
  name: "Aboutus",
  data() {
     return {
     }
  },
  created(){
  },
  computed:{
  },
  methods:{
  },
}
</script>

<style lang="css" scoped>
.main {
  width: 834px;
  background: white;
}
.side {
  width: 314px;
}
</style>
